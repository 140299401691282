import React from 'react'

import SEO from "../components/seo"

import Legal from "../layouts/legal"
import Main from "../components/main"
import PageHeader from '../components/chrome/pageHeader'
import PageFooter from '../components/chrome/pageFooter'
import Container from "../layouts/container"
import Breadcrumbs from "../components/chrome/breadcrumbs"

// eslint-disable-next-line no-unused-vars
import Styles from '../styles/styles.scss'

const PagePrivacy = () => (
    <Legal>
        <SEO title="Privacy statement/ policy" />
        <PageHeader headerText="Privacy statement/ policy" />

        <Main>

            <Container>
                <Breadcrumbs title="Privacy statement/ policy" path="/legal_notice" />
            </Container>

            <div className="row">
                <div className="col--16">
                    <Container>


                        <h1 className="h1">Privacy statement/ policy for mood-design.net</h1>
                        <p>At this website (in the following referred to as "we" or "us"), accessible from mood-design.net, we respect the privacy of our visitors. This privacy statement document contains information on how visitor data is collected and recorded by us and how we use it.</p>

                        <p><em>At the time of writing we are not collecting data other than logfiles and we are not analysing those. We are not using tracking or other third-party technology.</em></p>

                        <p>If you have additional questions or require more information about our privacy statement, do not hesitate to contact us through email at info@mood-design.net</p>

                        <ol className="terms">
                            <li className="terms__paragraph">
                                <h2 className="h2">Amazon Partner</h2>
                                <p>As a member of the Amazon Partnernet I earn some money with qualified sales (through so called affiliate links).</p>
                            </li>
                            <li className="terms__paragraph">
                                <h2 className="h2">General Data Protection Regulation (GDPR)</h2>

                                <p>Our legal basis for collecting and using the personal information described in this document depends on the data we collect and the specific context in which we collect the information:</p>
                                <ul className="unordered">
                                    <li className="unordered__entry">We need to perform a contract with you</li>
                                    <li className="unordered__entry">You have given mood-design.net permission to do so</li>
                                    <li className="unordered__entry">Processing your personal information is in our legitimate interests</li>
                                    <li className="unordered__entry">We need to comply with the law</li>
                                </ul>

                                <p>We will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>

                                <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what personal information we hold about you and if you want it to be removed from our systems, please contact us. Our Privacy statement was generated with the help of <a rel="noopener noreferrer" target="_blank" href="https://www.gdprprivacypolicy.net.com/">GDPR Privacy Policy Generator</a> and modified thereafter.</p>

                                <p>In certain circumstances, you have the following data protection rights:</p>
                                <ul className="unordered">
                                    <li className="unordered__entry">The right to access, update or to delete the information we have on you.</li>
                                    <li className="unordered__entry">The right to object.</li>
                                    <li className="unordered__entry">The right of restriction.</li>
                                    <li className="unordered__entry">The right to data portability</li>
                                    <li className="unordered__entry">The right to withdraw consent</li>
                                    <li className="unordered__entry">The right of rectification.</li>
                                </ul>
                            </li>

                            <li className="terms__paragraph">
                                <h2 className="h2">Log Files</h2>
                                <p>These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
                                <p><em>At the time of writing we are not analysing the information.</em></p>
                            </li>

                            <li className="terms__paragraph">
                                <h2 className="h2">Privacy policies</h2>
                                <p>You may consult this list to find the Privacy policy for each of the advertising partners of mood-design.net.</p>
                                <p><em>At the time of writing we are not partnering with any advertising partners.</em></p>
                                <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on this website, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
                                <p>Note that we do not have no access to or control over these cookies that are used by third-party advertisers.</p>
                            </li>

                            <li className="terms__paragraph">
                                <h2 className="h2">Third Party Privacy Policies</h2>
                                <p>Our Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>
                                <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.?</p>
                            </li>

                            <li className="terms__paragraph">
                                <h2 className="h2">Children's Information</h2>
                                <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
                                <p>We do not knowingly collect any personal identifiable information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
                            </li>

                            <li className="terms__paragraph">
                                <h2 className="h2">Online Privacy Policy Only</h2>
                                <p>Our Privacy Policy (created at GDPRPrivacyPolicy.net) applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect on this website. This policy is not applicable to any information collected offline or via channels other than this website.</p>
                            </li>

                            <li className="terms__paragraph">
                                <h2 className="h2">Consent</h2>
                                <p>By using our website, you hereby consent to our privacy statement/ policy and agree to its terms.</p>
                            </li>
                        </ol>
                    </Container>
                </div>
            </div>
        </Main>

        <PageFooter />
    </Legal>
);

export default PagePrivacy